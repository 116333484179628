
import { debounce } from "@evercam/shared/utils"
export default {
  props: {
    origin: {
      type: String,
      default: "",
    },
    params: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, undefined],
      default: undefined,
    },
    width: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data: () => ({
    iframeStyle: {
      border: "none",
    },
  }),
  watch: {
    width() {
      this.updateFrameDimensions()
    },
    height() {
      this.updateFrameDimensions()
    },
  },
  async mounted() {
    this.$addEventListener("message", this.onMessageReceived)
  },
  methods: {
    async onLoadIFrame() {
      await this.updateFrameDimensions()
    },
    async onMessageReceived(evt) {
      if (evt?.origin !== this.origin) {
        return
      }

      this.$emit("on-message", { ...evt.data, origin: evt.origin })
    },
    async onResizeERow() {
      this.$clearTimeouts()
      this.$setTimeout(async () => {
        await this.updateFrameDimensions()
      }, 100)
    },
    updateFrameDimensions: debounce(async function () {
      if (this.width || this.height) {
        let style = {
          ...this.iframeStyle,
        }

        if (this.width) {
          style.width = `${this.width}px`
        }
        if (this.height) {
          style.height = `${this.height}px`
        }

        if (isNaN(this.width) && this.height) {
          style.width = "100%"
        }

        this.iframeStyle = {
          ...style,
        }

        return
      }

      const iframe = this.$refs.container
      const container = await iframe?.getBoundingClientRect()

      this.iframeStyle = {
        ...this.iframeStyle,
        height: `${window.innerHeight - container?.top}px`,
        width: `${window.innerWidth - container?.left}px`,
      }
    }, 100),
  },
}
